/** @jsx jsx */
import {
  ComponentProps,
  memo,
  ReactChild,
  ReactElement,
  ReactNode,
} from "react";
import { jsx, Label } from "theme-ui";

import DUISubheading from "./DUISubheading";

export interface DUIInputLabelProps extends ComponentProps<typeof Label> {
  text: ReactChild;
  children: ReactNode;
}

export const DUIInputLabel = memo(function DUIInputLabel({
  text,
  children,
  ref: _,
  ...otherProps
}: DUIInputLabelProps): ReactElement {
  return (
    <Label {...otherProps} sx={{ display: "flex", flexDirection: "column" }}>
      <DUISubheading mb={2} sx={{ color: "gray.4" }}>
        {text}
      </DUISubheading>
      {children}
    </Label>
  );
});
export default DUIInputLabel;
