/** @jsx jsx */
import { DUIButton, DUIInput, DUIText } from "@alch/ui";
import { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";

import DUIInputLabel from "../components/DUIInputLabel";
import MasterLayout from "../components/MasterLayout";
import Section from "../components/Section";
import { fullWidthSx } from "../components/styles";
import ValidatingForm from "../components/ValidatingForm";
import { LoginResult, updatePassword } from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";

const NewPasswordPage = memo(function NewPasswordPage(): ReactElement {
  const token = useQueryParam("token", StringParam)[0] ?? undefined;
  const [password, setPassword] = useState("");
  const [result, setResult] = useState(Loading.unloaded<LoginResult>());
  const waitForLoad = useWaitForLoad();

  const handleSubmit = useCallback(() => {
    if (!token) {
      console.error("Cannot update password without token.");
      return;
    }
    waitForLoad(updatePassword({ token, password }), setResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [password]);

  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
  }, [token]);

  useEffect(() => {
    const redirectTo = result.value?.redirectTo;
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [result.value?.redirectTo]);

  return (
    <MasterLayout title="New Password">
      <Section centered={true}>
        <DUIText size="2xl">Choose new password</DUIText>
        <DUIText size="md" mt={2} sx={{ maxWidth: "300px", color: "gray.4" }}>
          Enter a new password to complete password reset.
        </DUIText>
      </Section>
      <Section shaded={true}>
        <ValidatingForm onSubmitPreventDefault={handleSubmit}>
          <DUIInputLabel text="New password">
            <DUIInput
              type="password"
              placeholder="••••••••"
              required={true}
              minLength={10}
              autoComplete="password"
              value={password}
              onValueChange={setPassword}
            />
            <DUIButton
              mt={9}
              sx={fullWidthSx}
              size="lg"
              type="submit"
              disabled={result.isLoading}
            >
              Set password
            </DUIButton>
            {result.error && (
              <DUIText
                size="lg"
                mt={3}
                sx={{ textAlign: "center", color: "danger.core" }}
              >
                {result.error?.message ?? "An unknown error occurred."}
              </DUIText>
            )}
          </DUIInputLabel>
        </ValidatingForm>
      </Section>
    </MasterLayout>
  );
});
export default NewPasswordPage;
