import { RefObject, useEffect, useRef } from "react";

import { ErrorMetadatable } from "../http/axios";

export type Loading<T> = LoadingWithValue<T> | LoadingNoValue<T>;

export interface LoadingBase<T> {
  isLoading?: boolean;
  hasValue?: boolean;
  value?: T;
  error?: ErrorValue;
}

export interface LoadingWithValue<T> extends LoadingBase<T> {
  hasValue: true;
  value: T;
}

export interface LoadingNoValue<T> extends LoadingBase<T> {
  hasValue?: false;
  value?: undefined;
}

interface ErrorValue {
  message?: string;
  metadata?: Record<string, any>;
}

const UNLOADED: LoadingNoValue<any> = {};

const IN_PROGRESS: LoadingNoValue<any> = { isLoading: true };

function unloaded<T>(): Loading<T> {
  return UNLOADED;
}

function inProgress<T>(): Loading<T> {
  return IN_PROGRESS;
}

function done<T>(value: T): Loading<T> {
  return { hasValue: true, value };
}

function error(message?: string, metadata?: Record<string, any>): Loading<any> {
  return { error: { message, metadata } };
}

export const Loading = { unloaded, inProgress, done, error };

/**
 * Returns a function which waits for a promise to finish, then stores its
 * result using the provided `store` function which will typically be a state
 * setter. This is a hook so it can not call the `store` function if the
 * component has already unmounted, as setting state on unmounted components is
 * a warning.
 */
export function useWaitForLoad(): <T>(
  load: Promise<T>,
  store: (result: Loading<T>) => void,
) => void {
  const isMounted = useIsMounted();

  return useRef(
    <T>(load: Promise<T>, store: (result: Loading<T>) => void): void => {
      function storeIfMounted(result: Loading<any>): void {
        if (isMounted.current) {
          store(result);
        }
      }

      load.then(
        (value) => storeIfMounted(Loading.done(value)),
        (error: ErrorMetadatable) => {
          storeIfMounted(Loading.error(error?.message, error?.metadata));
        },
      );
    },
  ).current;
}

function useIsMounted(): RefObject<boolean> {
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );
  return isMounted;
}
