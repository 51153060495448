/** @jsx jsx */
import { ComponentProps, memo, ReactElement } from "react";
import { jsx, Text } from "theme-ui";

export type DUISubheadingProps = ComponentProps<typeof Text>;

export const DUISubheading = memo(function DUISubheading(
  props: DUISubheadingProps,
): ReactElement {
  return (
    <Text
      {...props}
      sx={{ fontSize: 2, textTransform: "uppercase", letterSpacing: "2px" }}
    />
  );
});
export default DUISubheading;
