/** @jsx jsx */
import {
  FormEvent,
  forwardRef,
  memo,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { Box, BoxProps, jsx } from "theme-ui";

export interface ValidatingFormProps extends BoxProps {
  onSubmitPreventDefault?(event: FormEvent<HTMLFormElement>): void;
}

/**
 * Like a perfectly normal `<form>`, except that it highlights invalid inputs in
 * red once the user attempts to submit it once. This is useful because the
 * input validation always applies the `:invalid` pseudo-class while the input
 * is invalid, even if the user has not yet interacted with the page. This means
 * that styling fields using `:invalid` would make any required fields show up
 * as red when the page first loads, which is undesirable. Instead, wait for the
 * user to try submitting before highlighting invalid fields.
 */
export const ValidatingForm = memo(
  forwardRef<HTMLFormElement, ValidatingFormProps>(function ValidatingForm(
    { onInvalid, onSubmit, onSubmitPreventDefault, ...otherProps },
    ref,
  ): ReactElement {
    const [shouldShowValidation, setShouldShowValidation] = useState(false);

    const handleInvalid = useCallback(
      (event: FormEvent<any>) => {
        onInvalid?.(event);
        setShouldShowValidation(true);
      },
      [onInvalid],
    );

    const handleSubmit = useCallback(
      (event: FormEvent<any>) => {
        if (onSubmitPreventDefault) {
          event.preventDefault();
          onSubmitPreventDefault(event);
        }
        onSubmit?.(event);
      },
      [onSubmitPreventDefault, onSubmit],
    );

    return (
      <Box
        {...otherProps}
        ref={ref as any}
        as="form"
        sx={
          shouldShowValidation
            ? {
                "& input:invalid": {
                  borderColor: "danger.core",
                  backgroundColor: "danger.lighter",
                },
              }
            : {}
        }
        onInvalid={handleInvalid}
        onSubmit={handleSubmit}
      />
    );
  }),
);
export default ValidatingForm;
